<template>
  <ClFormRow :label="label" :toolTipText="tooltip" class="mb-4">
    <template #content>
      <multiselect
        v-model="selectedLanguage"
        :options="langOptions"
        :searchable="searchable"
        :multiple="multiple"
        :preserve-search="true"
        :taggable="false"
        :selectLabel="selectLabel"
        class="
          custom-multi-select
          zq--multiselect
          zq--multiselect__select
          zq--multiselect__green
        "
      />
    </template>
  </ClFormRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import entities from '@/config/modelConfig.js';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
export default {
  components: {
    ClFormRow,
  },
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    selectLabel: String,
    tagPlaceholder: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedLanguage: null,
      langOptions: [],
      options: entities,
      sortBy: [
        {
          queryField: 'name',
          order: 'Asc',
        },
      ],
      page: 100,
      languages: [],
    };
  },
  computed: {
    ...mapGetters('languages', ['success', 'message', 'loading']),
    ...mapGetters('lookups', ['languagesList']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';
      return {
        [className]: this.entityValidate === false,
      };
    },
  },
  watch: {
    selectedLanguage: {
      deep: true,
      handler(n, o) {
        if (n != o) {
          const key = this.languagesList.find(
            (x) => x.name === this.selectedLanguage
          )?.key;
          this.$emit('input', key ? key : null);
        }
      },
    },
    languagesList: {
      deep: true,
      handler: function () {
        this.filterLanguages();
      },
    },
  },
  methods: {
    ...mapActions('languages', ['handleGetLanguages']),
    ...mapActions('lookups', ['handleGetAvailableLanguagesList']),
    getExistingLanguages() {
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      this.handleGetLanguages([], null, null).then((items) => {
        for (let i in items) {
          this.languages.push(items[i].name);
        }
        //Check If languages are present and remove
        if (this.languages.length >= 0 && this.languagesList.length > 0) {
          this.filterLanguages();
        }
      });
    },
    filterLanguages() {
      this.langOptions = this.languagesList
          .filter(item => !this.languages.includes(item.name))
          .map(item => (item.name));
    },
  },
  async created() {
    await this.handleGetAvailableLanguagesList({
      queryData: {},
      sortBy: this.sortBy,
      limit: 662,
      skip: 0,
    });
    await this.getExistingLanguages();

    if (this.value) {
      const name = this.languagesList.find((x) => x.key === this.value).name;
      this.selectedLanguage = name;
      console.log(this.value, name, this.selectedLanguage);
    }
  },
};
</script>
